import React from "react"
import { Link } from "gatsby"

const NavBlogPost = ({ previous, next }) => {
  return (
    <nav className="blog-post-nav">
      <ul className="blog-post-nav__lists">
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              <span role="img" aria-label="left">
                👈
              </span>{" "}
              {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title}{" "}
              <span role="img" aria-label="right">
                👉
              </span>
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default NavBlogPost
