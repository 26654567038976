import React from "react"
import { graphql } from "gatsby"

import Bio from "@/components/bio"
import Layout from "@/components/layout"
import NavBlogPost from "@/components/nav-blog-post"
import SEO from "@/components/seo"
import Share from "@/components/share"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.image}
      />
      <article className="blog-post">
        <header className="blog-post__header">
          <h1 className="blog-post__headline">{post.frontmatter.title}</h1>
          <small className="blog-post__date">{post.frontmatter.date}</small>
        </header>
        <section
          className="markdown-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <Share
          title={post.frontmatter.title}
          url={`${location.href}`}
          description={post.frontmatter.description || post.excerpt}
        />
        <hr />

        <footer>
          <Bio />
        </footer>
      </article>
      <NavBlogPost previous={previous} next={next} />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
